import React from 'react'
import { Router } from "@reach/router"
import { Invitation } from '../components/Invitation'

const FALLBACK_CREATE_DM_URL = username => `mzeapp://message/${username}`

export const CreateDMLandingPage = ({ username }) => (
  <Invitation
    headerText={(
      <span>
        {username == null ? "Someone" : username} wants to message you on muze
      </span>
    )}
    joinURL={FALLBACK_CREATE_DM_URL(username)}
  />
)

export default () => (
  <Router>
    <CreateDMLandingPage path="/message/:username" />
  </Router>
)
